const DELIVERABLE_ACTIONS = {
  deliverable_action_meet_at_door: 'deliverable_action_meet_at_door',
  deliverable_action_leave_at_door: 'deliverable_action_leave_at_door',
};
const DELIVERABLE_ACTIONS_DISPLAY = {
  deliverable_action_meet_at_door: '門口見面',
  deliverable_action_leave_at_door: '放在門口',
};

const INVOICE_TYPE = {
  manual_invoice: '個人-電子發票',
  mobile_carrier: '個人-載具條碼',
  uni_no: '公司-電子發票',
  donation_code: '愛心碼',
};

const INVOICE_PLACEHOLDER = {
  mobile_carrier: '/+7個英數字符號',
  uni_no: '8個數字',
  donation_code: '愛心碼',
};

const INVOICE_CARRIER_LABEL = {
  mobile_carrier: '載具號碼',
  uni_no: '統一編號',
  donation_code: '愛心碼',
};

const DONATION_ORG = {
  104: '中華民國小胖威利病友關懷協會',
  168568: '中華民國弱勢者希望協會',
  2999: '中華民國微光社會福利協會',
  5000: '中華民國智障者家長總會',
  52616: '中華民國更生少年關懷協會',
  885885: '中華民國腦性麻痺協會',
  7505: '中華社會福利聯合勸募協會',
  860713: '保護動物協會',
  99520: '台灣動物保護協進會',
  958: '台灣動物緊急救援推廣協會',
  5299: '台灣導盲犬協會',
  5874: '台灣酷兒權益推動聯盟',
  9972: '社團法人中華小腦萎縮症病友協會',
  299: '社團法人中華民國保護動物協會',
  1801: '社團法人中華民國紅鼻子關懷小丑協會',
  5275: '社團法人中華民國肌萎縮症病友協會',
  2628: '社團法人中華民國脊髓損傷者聯合會',
  852: '社團法人中華民國視障愛心協會',
  5252: '社團法人中華民國身心障礙聯盟',
  178856: '社團法人中華長照協會',
  4545: '社團法人台灣全民食物銀行協會',
  5205: '社團法人台灣動物平權促進會',
  5380: '社團法人台灣失智症協會',
  921314: '社團法人台灣愛貓協會',
  3405: '社團法人台灣懷生相信動物協會',
  5266: '社團法人台灣有點溫暖毛孩協會',
  16899: '社團法人台灣癲癇之友協會',
  675: '社團法人台灣脊髓肌肉萎縮症病友協會',
  9118595: '社團法人勵馨社會福利事業基金會',
  9527: '財團法人罕見疾病基金會',
  8957282: '財團法人流浪動物之家基金會',
  876: '財團法人心路社會福利基金會',
  590: '財團法人弘道老人福利基金會',
  17258: '財團法人孩子的書屋文教基金會',
  88432: '財團法人喜憨兒社會福利基金會',
  2597885: '財團法人台灣關愛基金會',
  1117: '財團法人台灣早產兒基金會',
  818585: '財團法人台灣兒童暨家庭扶助基金會',
  52772: '財團法人台北市自閉兒社會福利基金會',
  13579: '財團法人陽光社會福利基金會',
};

const COUPON_TEMPLATE_TYPE = {
  COUPON_CASH_DISCOUNT: '折價券',
  COUPON_TWO_FOR_ONE: '買1送1',
  COUPON_LEVEL_UP: '升級券',
  COUPON_EXCHANGE: '餐點券',
  COUPON_SHOWCASE: '贈品券',
  COUPON_DRINK_EXCHANGE: '飲料兌換券(app視為餐點券)',
};

const COUPON_OBTAIN_METHOD = {
  SYSTEM: '系統派發',
  POINTS: '點數',
  STAMP: '印花集章',
  SCRATCH: '刮刮卡',
};

export {
  DELIVERABLE_ACTIONS,
  DELIVERABLE_ACTIONS_DISPLAY,
  INVOICE_TYPE,
  INVOICE_PLACEHOLDER,
  INVOICE_CARRIER_LABEL,
  DONATION_ORG,
  COUPON_TEMPLATE_TYPE,
  COUPON_OBTAIN_METHOD,
};
