import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {useOutlet} from 'reconnect.js';
import {showAdminSearchArticleModal} from '../../Components/AdminSearchArticleModal/index.js';

export default function AdminSelectArticleWidget(props) {
  const [articles] = useOutlet('articles');
  const [selectedArticle, setSelectedArticle] = React.useState(null);

  React.useEffect(() => {
    if (props.value) {
      setSelectedArticle(
        articles.find((article) => article.id === props.value),
      );
    }
  }, [props.value, articles]);

  return (
    <Wrapper>
      <div style={{wordBreak: 'break-word'}}>
        {!props.value
          ? '尚未設定文章'
          : !selectedArticle
          ? props.value
          : `【 ${selectedArticle.title} 】`}
      </div>
      <Button
        onClick={() => {
          props.onChange('');
          setSelectedArticle(null);
        }}
        style={{marginRight: 10}}>
        清除
      </Button>

      <Button
        type="primary"
        onClick={() =>
          showAdminSearchArticleModal({
            onChange: (id) => {
              props.onChange(id);
            },
          })
        }>
        搜尋
      </Button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  border: 1px solid #d9d9d9;
  padding: 15px;

  & > div {
    margin-right: 15px;
    letter-space: 1.5;
  }
`;
