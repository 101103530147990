import React from 'react';
import * as Ant from 'antd';
import AdminSelectCouponTemplateWidget from '../../Generators/AdminResource/AdminSelectCouponTemplateWidget';
import AdminDateTimeWidget from '../../Generators/AdminResource/AdminDateTimeWidget';
import AdminArrayTable from '../../Components/AdminArrayTable';
import RevSingleImageUploader from 'rev.sdk.js/Generic/CustomWidgets/RevSingleImageUploader';

// {
//   redeem_rules: {
//     expired_time: {$numberLong: '9999999999999999'},
//     gift_rules: [
//       {
//         name: '',
//         cost_stamps: {$numberInt: '10'},
//         gift: {type: 'PHYSICAL', name: '馬克杯'},
//         alternative_gift: {
//           type: 'COUPON',
//           name: '某個優惠券',
//           coupon_template: '65f16f193c056daada66327e',
//         },
//       },
//     ],
//   },
// "doubling_dates":[{"start_time":"1722528000000","end_time":"1722614340000"}],
// "reservation_extra_dates": :[{"start_time":"1722528000000","end_time":"1722614340000"}],
// first_consumption_extra_dates:[{"start_time":"1722528000000","end_time":"1722614340000"}],
// }

export default function DragonBallStamp({record, setRecord}) {
  const setGiftRules = (idx, data) => {
    let gift_rules = [...record.redeem_rules.gift_rules];
    gift_rules[idx] = {...gift_rules[idx], ...data};
    setRecord((prev) => ({
      ...prev,
      redeem_rules: {...prev.redeem_rules, gift_rules},
    }));
  };

  return (
    <React.Fragment>
      <h2>進階設定</h2>

      <Ant.Row>
        <Ant.Col span={2} className="label">
          兌換截止日
        </Ant.Col>
        <Ant.Col span={6}>
          <AdminDateTimeWidget
            value={record.redeem_rules.expired_time}
            onChange={(value) =>
              setRecord((prev) => ({
                ...prev,
                redeem_rules: {...prev.redeem_rules, expired_time: value},
              }))
            }
          />
        </Ant.Col>
      </Ant.Row>

      <Ant.Row>
        <Ant.Col span={2} className="label">
          印花活動規則上方圖片
        </Ant.Col>
        <Ant.Col span={6}>
          <RevSingleImageUploader
            value={record.event_top_image}
            onChange={(value) =>
              setRecord((prev) => ({...prev, event_top_image: value}))
            }
            schema={{}}
          />
        </Ant.Col>

        <Ant.Col span={2} className="label">
          印花活動規則下方圖片
        </Ant.Col>
        <Ant.Col span={6}>
          <RevSingleImageUploader
            value={record.event_bottom_image}
            onChange={(value) =>
              setRecord((prev) => ({...prev, event_bottom_image: value}))
            }
            schema={{}}
          />
        </Ant.Col>

        <Ant.Col span={2} className="label">
          兌換印花圖片
        </Ant.Col>
        <Ant.Col span={6}>
          <RevSingleImageUploader
            value={record.exchange_image}
            onChange={(value) =>
              setRecord((prev) => ({...prev, exchange_image: value}))
            }
            schema={{}}
          />
        </Ant.Col>
      </Ant.Row>
      <Ant.Row>
        <Ant.Col span={2} className="label">
          活動描述標題1(棄用)
        </Ant.Col>
        <Ant.Col span={6}>
          <Ant.Input
            value={record.description_title_1}
            onChange={(e) => {
              let value = e.target.value;
              setRecord((prev) => ({...prev, description_title_1: value}));
            }}
          />
        </Ant.Col>
        <Ant.Col span={2} className="label">
          活動描述內容1(棄用)
        </Ant.Col>
        <Ant.Col span={14}>
          <Ant.Input.TextArea
            rows={3}
            value={record.description_content_1}
            onChange={(e) => {
              let value = e.target.value;
              setRecord((prev) => ({...prev, description_content_1: value}));
            }}
          />
        </Ant.Col>
      </Ant.Row>
      <Ant.Row>
        <Ant.Col span={2} className="label">
          活動描述標題2(棄用)
        </Ant.Col>
        <Ant.Col span={6}>
          <Ant.Input
            value={record.description_title_2}
            onChange={(e) => {
              let value = e.target.value;
              setRecord((prev) => ({...prev, description_title_2: value}));
            }}
          />
        </Ant.Col>
        <Ant.Col span={2} className="label">
          活動描述內容2(棄用)
        </Ant.Col>
        <Ant.Col span={14}>
          <Ant.Input.TextArea
            rows={3}
            value={record.description_content_2}
            onChange={(e) => {
              let value = e.target.value;
              setRecord((prev) => ({...prev, description_content_2: value}));
            }}
          />
        </Ant.Col>
      </Ant.Row>

      <h2>印花設定</h2>

      <AdminArrayTable
        columns={[
          {
            title: '兌換方式',
            span: 6,
            render: (_, item, idx) => (
              <div>
                <div>規則名稱</div>
                <Ant.Input
                  value={item.name}
                  onChange={(e) => {
                    let value = e.target.value;
                    setGiftRules(idx, {name: value});
                  }}
                />
                <div style={{color: '#bbb', fontSize: 12, marginBottom: 8}}>
                  此欄位會對應分店活動設定，請勿隨意修改
                </div>
                <div>消耗印花數量</div>
                <Ant.Input
                  type="number"
                  min={0}
                  value={item.cost_stamps}
                  onChange={(e) => {
                    let value = parseInt(e.target.value || 0);
                    setGiftRules(idx, {cost_stamps: value});
                  }}
                />
                <div>補差價</div>
                <Ant.Input
                  value={item.cost_price}
                  onChange={(e) => {
                    let value = parseInt(e.target.value || 0);
                    setGiftRules(idx, {cost_price: value});
                  }}
                />
              </div>
            ),
          },
          {
            title: '贈品',
            span: 8,
            render: (_, item, idx) => (
              <div>
                <div>種類</div>
                <Ant.Select
                  value={item.gift.type}
                  onChange={(value) =>
                    setGiftRules(idx, {gift: {...item.gift, type: value}})
                  }
                  options={[{value: 'PHYSICAL', label: 'PHYSICAL'}]}
                />
                <div>名稱</div>
                <Ant.Input
                  value={item.gift.name}
                  onChange={(e) => {
                    let value = e.target.value;
                    setGiftRules(idx, {gift: {...item.gift, name: value}});
                  }}
                />
                {/* <div>圖片</div>
                <RevSingleImageUploader
                  value={item.gift.image}
                  onChange={(value) => {
                    setGiftRules(idx, {gift: {...item.gift, image: value}});
                  }}
                  schema={{}}
                /> */}
              </div>
            ),
          },
          {
            title: '替代贈品',
            span: 8,
            render: (_, item, idx) => (
              <div>
                <div>種類</div>
                <Ant.Select
                  value={item.alternative_gift.type}
                  onChange={(value) =>
                    setGiftRules(idx, {
                      alternative_gift: {...item.alternative_gift, type: value},
                    })
                  }
                  options={[{value: 'COUPON', label: 'COUPON'}]}
                />
                <div>名稱</div>
                <Ant.Input
                  value={item.alternative_gift.name}
                  onChange={(e) => {
                    let value = e.target.value;
                    setGiftRules(idx, {
                      alternative_gift: {
                        ...item.alternative_gift,
                        name: value,
                      },
                    });
                  }}
                />
                <div>優惠券樣板</div>
                <AdminSelectCouponTemplateWidget
                  value={item.alternative_gift.coupon_template}
                  onChange={(value) =>
                    setGiftRules(idx, {
                      alternative_gift: {
                        ...item.alternative_gift,
                        coupon_template: value,
                      },
                    })
                  }
                />
              </div>
            ),
          },
        ]}
        dataSource={record.redeem_rules.gift_rules}
        defaultRecord={{
          name: '',
          cost_stamps: 10,
          gift: {type: 'PHYSICAL', name: ''},
          alternative_gift: {
            type: 'COUPON',
            name: '',
            coupon_template: '',
          },
        }}
        onChange={(next) =>
          setRecord((prev) => ({
            ...prev,
            redeem_rules: {...prev.redeem_rules, gift_rules: next},
          }))
        }
      />

      <h2>雙倍印花贈送時間</h2>

      <AdminArrayTable
        columns={[
          {
            title: '開始時間',
            span: 11,
            render: (_, item, idx) => (
              <AdminDateTimeWidget
                value={item.start_time}
                onChange={(value) => {
                  let next = [...(record.doubling_dates || [])];
                  next[idx]['start_time'] = value;
                  setRecord((prev) => ({...prev, doubling_dates: next}));
                }}
              />
            ),
          },
          {
            title: '結束時間',
            span: 11,
            render: (_, item, idx) => (
              <AdminDateTimeWidget
                value={item.end_time}
                onChange={(value) => {
                  let next = [...(record.doubling_dates || [])];
                  next[idx]['end_time'] = value;
                  setRecord((prev) => ({...prev, doubling_dates: next}));
                }}
              />
            ),
          },
        ]}
        dataSource={record.doubling_dates}
        defaultRecord={{
          start_time: undefined,
          end_time: undefined,
        }}
        onChange={(next) =>
          setRecord((prev) => ({
            ...prev,
            doubling_dates: next,
          }))
        }
      />

      <h2>預約加碼送</h2>

      <AdminArrayTable
        columns={[
          {
            title: '開始時間',
            span: 11,
            render: (_, item, idx) => (
              <AdminDateTimeWidget
                value={item.start_time}
                onChange={(value) => {
                  let next = [...(record.reservation_extra_dates || [])];
                  next[idx]['start_time'] = value;
                  setRecord((prev) => ({
                    ...prev,
                    reservation_extra_dates: next,
                  }));
                }}
              />
            ),
          },
          {
            title: '結束時間',
            span: 11,
            render: (_, item, idx) => (
              <AdminDateTimeWidget
                value={item.end_time}
                onChange={(value) => {
                  let next = [...(record.reservation_extra_dates || [])];
                  next[idx]['end_time'] = value;
                  setRecord((prev) => ({
                    ...prev,
                    reservation_extra_dates: next,
                  }));
                }}
              />
            ),
          },
        ]}
        dataSource={record.reservation_extra_dates}
        defaultRecord={{
          start_time: undefined,
          end_time: undefined,
        }}
        onChange={(next) =>
          setRecord((prev) => ({
            ...prev,
            reservation_extra_dates: next,
          }))
        }
      />

      <h2>第一次消費加碼送時間</h2>

      <AdminArrayTable
        columns={[
          {
            title: '開始時間',
            span: 11,
            render: (_, item, idx) => (
              <AdminDateTimeWidget
                value={item.start_time}
                onChange={(value) => {
                  let next = [...(record.first_consumption_extra_dates || [])];
                  next[idx]['start_time'] = value;
                  setRecord((prev) => ({
                    ...prev,
                    first_consumption_extra_dates: next,
                  }));
                }}
              />
            ),
          },
          {
            title: '結束時間',
            span: 11,
            render: (_, item, idx) => (
              <AdminDateTimeWidget
                value={item.end_time}
                onChange={(value) => {
                  let next = [...(record.first_consumption_extra_dates || [])];
                  next[idx]['end_time'] = value;
                  setRecord((prev) => ({
                    ...prev,
                    first_consumption_extra_dates: next,
                  }));
                }}
              />
            ),
          },
        ]}
        dataSource={record.first_consumption_extra_dates}
        defaultRecord={{
          start_time: undefined,
          end_time: undefined,
        }}
        onChange={(next) =>
          setRecord((prev) => ({
            ...prev,
            first_consumption_extra_dates: next,
          }))
        }
      />
    </React.Fragment>
  );
}
