import React from 'react';
import styled from 'styled-components';
import {Select} from 'antd';
import {useOutlet} from 'reconnect.js';

export default function AdminSelectVariantGroupWidget(props) {
  const [variantGroups] = useOutlet('variantGroups');
  const [selected, setSelected] = React.useState(null);

  React.useEffect(() => {
    setSelected(props.value);
  }, [props.value]);

  return (
    <Wrapper>
      <Select
        value={selected}
        options={variantGroups.map((v) => ({
          label: v.name,
          value: v.name,
        }))}
        onChange={props.onChange}
        style={{width: '100%'}}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  & > div {
    margin-right: 15px;
    letter-space: 1.5;
  }
`;
