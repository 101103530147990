import React from 'react';
import * as Ant from 'antd';
import AdminSelectCouponTemplateWidget from '../../Generators/AdminResource/AdminSelectCouponTemplateWidget';
import AdminArrayTable from '../../Components/AdminArrayTable';
import AdminDateTimeWidget from '../../Generators/AdminResource/AdminDateTimeWidget';

// {
//   cost_points: 10,
//   draw_box: [
//     {
//       award_type: 'COUPON',
//       award: {coupon_template: '65f16f193c056daada66327e'},
//       percentage: {$numberInt: '80'},
//     },
//     {
//       award_type: 'STAMP',
//       award: {
//         stamp_count: {$numberInt: '1'},
//         stamp_event: '6618d94b18740a193028f95e',
//       },
//       percentage: {$numberInt: '2000'},
//     },
//   ],
//   draw_box2: same,
//   extra_radar_settings: { start_time: , end_time:  }
// }

export default function DragonBall({record, setRecord}) {
  const setDrawBox = (boxName, idx, data) => {
    let draw_box = [...(record[boxName] || [])];
    draw_box[idx] = {...draw_box[idx], ...data};
    setRecord((prev) => ({...prev, [boxName]: draw_box}));
  };

  console.log('record', record);

  return (
    <React.Fragment>
      <h2>進階設定</h2>

      <Ant.Row style={{marginBottom: 10}}>
        <Ant.Col span={2} className="label">
          再抽一次消耗點數
        </Ant.Col>
        <Ant.Col span={22}>
          <Ant.Input
            value={record.cost_points}
            type="number"
            onChange={(e) => {
              let value = parseInt(e.target.value);
              setRecord((prev) => ({...prev, cost_points: value}));
            }}
          />
        </Ant.Col>
      </Ant.Row>

      <Ant.Row style={{marginBottom: 10}}>
        <Ant.Col span={2} className="label">
          額外贈送一次的活動開始時間
        </Ant.Col>
        <Ant.Col span={6}>
          <AdminDateTimeWidget
            value={record.extra_radar_settings?.start_time}
            onChange={(value) => {
              setRecord((prev) => ({
                ...prev,
                extra_radar_settings: {
                  ...prev.extra_radar_settings,
                  start_time: value,
                },
              }));
            }}
          />
        </Ant.Col>
        <Ant.Col span={2} className="label">
          額外贈送一次的活動結束時間
        </Ant.Col>
        <Ant.Col span={14}>
          <AdminDateTimeWidget
            value={record.extra_radar_settings.end_time}
            onChange={(value) => {
              setRecord((prev) => ({
                ...prev,
                extra_radar_settings: {
                  ...prev.extra_radar_settings,
                  end_time: value,
                },
              }));
            }}
          />
        </Ant.Col>
      </Ant.Row>

      {['draw_box', 'draw_box2'].map((boxName, index) => (
        <AdminArrayTable
          key={boxName}
          dataSource={record[boxName] || []}
          columns={[
            {
              title: '贈品類型',
              span: 6,
              render: (_, item, idx) => (
                <Ant.Radio.Group
                  value={item.award_type}
                  onChange={(e) => {
                    let value = e.target.value;
                    setDrawBox(boxName, idx, {award_type: value});
                  }}>
                  <Ant.Space direction="vertical">
                    <Ant.Radio value="COUPON">COUPON</Ant.Radio>
                    <Ant.Radio value="STAMP">STAMP</Ant.Radio>
                  </Ant.Space>
                </Ant.Radio.Group>
              ),
            },
            {
              title: '贈品細項',
              span: 10,
              render: (_, item, idx) =>
                item.award_type === 'COUPON' ? (
                  <div>
                    <div>優惠券樣板</div>
                    <AdminSelectCouponTemplateWidget
                      value={item.award.coupon_template}
                      onChange={(coupon_template) => {
                        console.log('setDrawBox', boxName, idx, {
                          award: {...item.award, coupon_template},
                        });

                        setDrawBox(boxName, idx, {
                          award: {...item.award, coupon_template},
                        });
                      }}
                    />
                  </div>
                ) : item.award_type === 'STAMP' ? (
                  <div style={{flex: 1}}>
                    <div>印花數量</div>
                    <Ant.Input
                      type="number"
                      min={0}
                      value={item.award.stamp_count}
                      onChange={(e) => {
                        let value = parseInt(e.target.value);
                        setDrawBox(boxName, idx, {
                          award: {...item.award, stamp_count: value},
                        });
                      }}
                    />

                    <div>活動編號</div>
                    <Ant.Input
                      placeholder="請由該活動複製，貼在此欄位"
                      value={item.award.stamp_event}
                      onChange={(e) => {
                        let value = e.target.value;
                        setDrawBox(boxName, idx, {
                          award: {...item.award, stamp_event: value},
                        });
                      }}
                    />
                  </div>
                ) : null,
            },
            {
              title: `第${index + 1}次獲獎機率（%）`,
              span: 6,
              render: (_, item, idx) => (
                <Ant.Input
                  type="number"
                  min={0}
                  value={item.percentage}
                  onChange={(e) => {
                    let value = parseInt(e.target.value);
                    setDrawBox(boxName, idx, {percentage: value});
                  }}
                />
              ),
            },
          ]}
          defaultRecord={{
            award_type: 'COUPON',
            award: {coupon_template: ''},
            percentage: 10,
          }}
          onChange={(values) =>
            setRecord((prev) => ({...prev, [boxName]: values}))
          }
        />
      ))}
    </React.Fragment>
  );
}
