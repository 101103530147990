import React from 'react';
import * as Ant from 'antd';

export default function NormalPoint({record, setRecord}) {
  return (
    <React.Fragment>
      <h2>進階設定</h2>

      <Ant.Row>
        <Ant.Col span={2} className="label">
          強制預約
        </Ant.Col>
        <Ant.Col span={22}>
          <Ant.Checkbox
            disabled
            checked={record.force_reservation}
            onChange={(e) => {
              setRecord((prev) => ({
                ...prev,
                force_reservation: e.target.checked,
              }));
            }}
          />
        </Ant.Col>
      </Ant.Row>
    </React.Fragment>
  );
}
