import React from 'react';
import styled from 'styled-components';

export default function AdminDateTimeWidget(props) {
  const {value, onChange, disabled, readonly} = props;

  let _value;
  if (value !== null) {
    _value = value ? new Date(value) : new Date();
    _value = new Date(_value)
      .toLocaleString('sv')
      .replace(' ', 'T')
      .slice(0, 16);
    // input value must be YYYY-MM-DDTHH:mm
  }

  return (
    <Wrapper>
      <input
        type="datetime-local"
        disabled={disabled || readonly}
        value={_value}
        onChange={(e) => {
          const value = e.target.value;

          if (!value) {
            onChange(null);
          } else if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/.test(value)) {
            const date = new Date(value);
            onChange(date.getTime()); // transform to timestamp
          }
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > input {
    border: 1px solid #d9d9d9;
    height: 30px;
    padding: 0px 11px;

    &:disabled {
      background-color: #f5f5f5;
      color: rgba(0, 0, 0, 0.25);
      border-color: #d9d9d9;
      cursor: not-allowed;
    }
  }
`;
